/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Link } from 'gatsby';

import { siteMap } from '../utils/sitemap';

function Hero() {
  return (
    <div className="relative pt-10 pb-20 sm:py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
        <div className="mx-auto max-w-2xl lg:max-w-4xl lg:px-12">
          <h1 className="font-display font-corsive text-2xl font-medium tracking-tighter sm:text-5xl">
            The Ledger Event
          </h1>
          <h1 className="font-display font-corsive text-xl font-medium tracking-tighter sm:text-2xl">
            Berlin / April 2023
          </h1>
          <h1 className="mt-6 font-display font-corsive text-5xl font-bold">
            <span className="block">An invite-only local event</span>
            where the finance community meets.
          </h1>
          <div className="mt-6 space-y-6 font-display text-2xl tracking-tight text-white-900">
            <p>
              The Ledger Event is a series of community events organized across Europe.
            </p>
            <p>
              The goal is to create a place where local finance communities can meet.
            </p>
            <p>
              Stay tuned for more info and check our
              {' '}
              <Link to={siteMap.HOME.link} className="underline">podcast</Link>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
