import React from 'react';
import Events from '../components/events';
import Layout from '../components/layout';
import Seo from '../components/seo';

function EventsPage({ location }) {
  return (
    <Layout>
      <Seo title="Events" path={location.pathname} description="A local event where the finance community meets." />
      <Events />
    </Layout>
  );
}

export default EventsPage;
